export const CDN_URL = process.env.NEXT_PUBLIC_CDN_URL ?? "";

export const PRIVY_REGISTER_URL = "https://app.privy.id/register";
export const GOOGLE_API_KEY = process.env.NEXT_PUBLIC_GOOGLE_API_KEY ?? "";

export const PRIVY_OAUTH = {
  clientId: process.env.NEXT_PUBLIC_PRIVY_OAUTH_CLIENT_ID ?? "",
  scope: process.env.NEXT_PUBLIC_PRIVY_OAUTH_SCOPE ?? "",
  url: process.env.NEXT_PUBLIC_PRIVY_OAUTH_URL ?? "",
  dashboardUrl: process.env.NEXT_PUBLIC_PRIVY_DASHBOARD_URL ?? "",
  callbackUrl:
    "https://account.eproc.dev/verification/step/document-verification",
};

export const PUBLIC = {
  BASE_URL: process.env.NEXT_PUBLIC_ACCOUNT_URL ?? "",
};

export const NOVU_CONFIGURATION: {
  novuAppId: string;
  novuBackendUrl: string;
  novuSocketUrl: string;
} = {
  novuAppId: process.env.NEXT_PUBLIC_NOVU_APP_ID ?? "",
  novuBackendUrl: process.env.NEXT_PUBLIC_API_URL ?? "",
  novuSocketUrl: process.env.NEXT_PUBLIC_WS_URL ?? "",
};

export const ANALYTICS = {
  GTM_ID: process.env.NEXT_PUBLIC_GTM_ID ?? "",
};

export const GOOGLE_SITE_VERIFICATION =
  process.env.NEXT_PUBLIC_GOOGLE_SITE_VERIFICATION;

export const ZENDESK_URL = process.env.NEXT_PUBLIC_ZENDESK_ACCOUNT_URL ?? "";

export const REGISTER_AHU_URL = "https://bo.ahu.go.id/site/login";

export const OSS_LINK = "https://oss.go.id/";
