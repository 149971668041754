import { getCookie } from "cookies-next"
import {
  AppsCookieName,
  EXPIRED_TIME_TOKEN_COOKIE_NAME,
  TAppsName,
  triggeredRefreshToken,
  AuthenticationErrorEnum,
} from "../authentication"
import { IS_DEVELOPMENT } from "../constants"
import {
  getAccountUserInfo,
  getUserInfo,
  cookieCSROptions,
} from "./authentication/cookies/userInfoStore"
import { getUnixTime } from "date-fns"

export type TRequest = {
  headers: Record<string, string>
  url: string
}

const envAppName =
  process?.env?.NEXT_PUBLIC_APP_NAME?.toUpperCase() as TAppsName

type TGraphqlClientMiddleware = {
  request: TRequest
  signOut: () => void
  app?: TAppsName
  baseUrl?: string
}

const checkExpiredToken = async ({
  token,
  url,
  signOut,
}: {
  token: string
  url: string
  signOut: () => void
}) => {
  if (!url) return token
  const expTimeToken = getCookie(EXPIRED_TIME_TOKEN_COOKIE_NAME, {
    ...cookieCSROptions,
  }) as string as unknown as number

  const currentTime = getUnixTime(new Date())
  const timeDifference = expTimeToken - currentTime
  const isOneMinuteDifference = timeDifference <= 60

  // check if current time almost expired 1 minute before expiredTimeToken
  if (isOneMinuteDifference) {
    const newToken = await triggeredRefreshToken(url)
    if (
      Object.keys(newToken).length < 1 ||
      newToken.error === AuthenticationErrorEnum.ERROR_ACCESS_TOKEN
    ) {
      signOut()
    }
    return newToken.token?.accessToken ?? ""
  }
  return token
}

const graphqlClientMiddleware = async ({
  request,
  signOut,
  app,
  baseUrl,
}: TGraphqlClientMiddleware) => {
  const appName = app || envAppName

  const userInfo = appName === "ACCOUNT" ? getAccountUserInfo() : getUserInfo()
  if (userInfo?.error === "ERROR_ACCESS_TOKEN") {
    signOut()
  }

  const headers: Record<string, string> = {
    "Content-Type": "application/json",
    "x-gtp-app": appName.toLowerCase(),
  }

  const tokenHeader = request.headers?.["access-token"]

  /**
   * Note: The access token header only use on localhost
   */
  if (IS_DEVELOPMENT && !tokenHeader) {
    headers["access-token"] = await checkExpiredToken({
      token: userInfo?.token?.accessToken ?? "",
      url: baseUrl ?? "",
      signOut,
    })

    delete headers["x-gtp-app"]
  } else if (tokenHeader) {
    const newToken = await checkExpiredToken({
      token: tokenHeader ?? "",
      url: baseUrl ?? "",
      signOut,
    })
    headers["Cookie"] = `${AppsCookieName?.[appName]}=${newToken}`
  } else {
    await checkExpiredToken({
      token: "",
      url: baseUrl ?? "",
      signOut,
    })
  }

  request.headers = {
    ...request.headers,
    ...headers,
  }

  return request
}

export default graphqlClientMiddleware
