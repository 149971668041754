import { TSession } from "../type"

export const triggeredRefreshToken = async (baseurl: string) => {
  const response = await fetch(`${baseurl}api/auth/session?forceRefresh=true`)
  const newSession = (await response?.json()) as TSession
  return newSession
}

export const updateUserSession = async (baseurl: string) => {
  const response = await fetch(`${baseurl}api/auth/session?userRefresh=true`)
  const newSession = (await response?.json()) as TSession
  return newSession
}

export const clearErrorSession = async (baseurl: string) => {
  await fetch(`${baseurl}api/auth/session?clearError=true`)
  return true
}
