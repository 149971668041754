import { GraphQLClient } from "graphql-request";
import {
  type RequestMiddleware,
  type Response,
} from "graphql-request/build/esm/types";
import {
  IS_PRODUCTION,
  LIST_ERROR_KEYWORD,
  type TRequest,
  checkUnauthorized,
} from "shared-utils";
import { graphqlClientMiddleware } from "shared-utils";
import {
  getAccountUserInfo,
  triggeredRefreshToken,
} from "shared-utils/authentication";

import sendRequestLog from "./sendRequestLog";
import { signIn, signOut } from "@/authentication/authService";
import { CMS_FEDERATED_TYK_API_URL, FEDERATED_TYK_API_URL } from "@/config/api";
import { PUBLIC } from "@/config/client";

const middleware: RequestMiddleware = async (request) => {
  const resultMiddleware = graphqlClientMiddleware({
    request: request as TRequest,
    signOut,
    app: "ACCOUNT",
    baseUrl: PUBLIC.BASE_URL,
  });

  await sendRequestLog({
    key: request.operationName || "",
    variables: request.variables,
    isMutation: request.body?.toString().includes("mutation"),
  });

  return resultMiddleware;
};

export const responseMiddleware: (
  response: Error | Response<unknown>
) => void = async (response) => {
  const stringifyRes = JSON.stringify(response);
  if (checkUnauthorized(stringifyRes)) {
    // TODO: change to signOut after fix SSR
    // signOut()
    if (typeof window === "undefined") {
      await triggeredRefreshToken(PUBLIC.BASE_URL);
    } else {
      signIn("refreshToken");
    }
    return response;
  }

  const userInfo = getAccountUserInfo();
  if (
    IS_PRODUCTION &&
    !!userInfo?.user?.id &&
    LIST_ERROR_KEYWORD.some((keyword) => stringifyRes.includes(keyword))
  ) {
    try {
      await fetch("/api/chat_log", {
        method: "POST",
        body: JSON.stringify({
          text: `APP:*ACCOUNT* \n\nUSER_ID: *${userInfo?.user?.username} - ${userInfo?.user?.id}* \n \nERROR_MESSAGE: \n${stringifyRes}`,
        }),
      });
    } catch (error) {}
  }

  return response;
};

/**
 * federatedGqlClient works for client components
 */
export const federatedGqlClient = new GraphQLClient(FEDERATED_TYK_API_URL, {
  requestMiddleware: middleware,
  responseMiddleware: responseMiddleware,
  credentials: "include",
});

export const cmsFederatedGqlClient = new GraphQLClient(
  CMS_FEDERATED_TYK_API_URL,
  {
    requestMiddleware: middleware,
    responseMiddleware: responseMiddleware,
    credentials: "include",
  }
);
