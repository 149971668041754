import { IS_PRODUCTION } from "shared-utils";
import { getAccountUserInfo } from "shared-utils/authentication";

import { MONITORED_QUERIES_MUTATIONS } from "@/constant";

type TSendErrorLog<TVariable> = {
  key: string;
  variables: TVariable;
  isMutation?: boolean;
};

const sendRequestLog = async <TVariable>({
  key,
  variables,
  isMutation,
}: TSendErrorLog<TVariable>) => {
  if (!IS_PRODUCTION || !MONITORED_QUERIES_MUTATIONS.includes(key)) {
    return;
  }

  const userInfo = getAccountUserInfo();
  try {
    await fetch("/api/chat_log", {
      method: "POST",
      body: JSON.stringify({
        text: `APP:*ACCOUNT*\n\nTYPE:*REQUEST LOG*\n\nUSER_ID: *${
          userInfo?.user?.username
        } - ${userInfo?.user?.id}* \n \n${
          isMutation ? "MUTATION" : "QUERY"
        } : *${key}* \n \nPAYLOAD: \n *${JSON.stringify(variables)}*`,
      }),
    });
  } catch (err) {}
};

export default sendRequestLog;
